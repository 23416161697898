@font-face { font-family: 'fender-icons'; src: url( 'fonts/fender-icons.ttf?wsiax1' ) format( 'truetype' ), url( 'fonts/fender-icons.woff?wsiax1' ) format( 'woff' ), url( 'fonts/fender-icons.svg?wsiax1#fender-icons' ) format( 'svg' ); font-weight: normal; font-style: normal; font-display: block; }
[class^="icon-"], [class*=" icon-"] {/* use !important to prevent issues with browser extensions that change fonts */ font-family: 'fender-icons' !important; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
.icon-playlists-filled:before { content: "\e99e"; }
.icon-playlists:before { content: "\e99f"; }
.icon-document-course-detail:before { content: "\e99b"; color: #666; }
.icon-check-green:before { content: "\e99d"; color: #2ac278; }
.icon-check-mark-complete:before { content: "\e99c"; color: #666; }
.icon-heart:before { content: "\e999"; color: #888; }
.icon-heart-filled:before { content: "\e99a"; color: #d52b1e; }
.icon-megaphone-fill:before { content: "\e997"; }
.icon-megaphone:before { content: "\e998"; }
.icon-count-in-text:before { content: "\e996"; }
.icon-speed:before { content: "\e995"; }
.icon-video-detached:before { content: "\e993"; }
.icon-video-splitscreen:before { content: "\e994"; }
.icon-arrow-active-lesson:before { content: "\e992"; }
.icon-chat:before { content: "\e991"; }
.icon-flame:before { content: "\e989"; }
.icon-x-circle-fill:before { content: "\e98a"; }
.icon-ellipsis-vertical:before { content: "\e98b"; }
.icon-list:before { content: "\e98c"; }
.icon-layout:before { content: "\e98d"; }
.icon-calendar:before { content: "\e98e"; }
.icon-x-thin:before { content: "\e988"; }
.icon-expand:before { content: "\e987"; }
.icon-arrow-indicator:before { content: "\e986"; }
.icon-pause:before { content: "\e98f"; }
.icon-metronome:before { content: "\e990"; }
.icon-elipsis-alt:before { content: "\e985"; }
.icon-search-thin:before { content: "\e983"; }
.icon-elipsis-more:before { content: "\e984"; }
.icon-my-progress-filled:before { content: "\e97d"; }
.icon-skills-filled:before { content: "\e97e"; }
.icon-songs-filled:before { content: "\e97f"; }
.icon-toolkit-filled:before { content: "\e980"; }
.icon-home-filled:before { content: "\e981"; }
.icon-sign-out:before { content: "\e982"; }
.icon-toolkit:before { content: "\e96c"; }
.icon-app-switcher:before { content: "\e96a"; }
.icon-support:before { content: "\e96b"; }
.icon-settings:before { content: "\e96d"; }
.icon-my-favorites:before { content: "\e96e"; }
.icon-my-progress:before { content: "\e96f"; }
.icon-articles:before { content: "\e970"; }
.icon-play-lists:before { content: "\e971"; }
.icon-profile:before { content: "\e972"; }
.icon-songs:before { content: "\e973"; }
.icon-skills:before { content: "\e974"; }
.icon-browse:before { content: "\e975"; }
.icon-chord-diagram:before { content: "\e976"; }
.icon-info-circle:before { content: "\e977"; }
.icon-tablature:before { content: "\e978"; }
.icon-tuning-fork:before { content: "\e979"; }
.icon-home:before { content: "\e97a"; }
.icon-book:before { content: "\e97b"; }
.icon-alarm:before { content: "\e97c"; color: #999; }
.icon-lock:before { content: "\e969"; }
.icon-play-pick:before { content: "\e968"; }
.icon-alert:before { content: "\e900"; }
.icon-amp:before { content: "\e901"; }
.icon-arrow-down:before { content: "\e902"; }
.icon-arrow-left:before { content: "\e903"; }
.icon-arrow-right:before { content: "\e904"; }
.icon-arrow-right-circle:before { content: "\e905"; }
.icon-arrow-up:before { content: "\e906"; }
.icon-badge-check:before { content: "\e907"; }
.icon-bolt:before { content: "\e908"; }
.icon-bolt-45c:before { content: "\e909"; }
.icon-box:before { content: "\e90a"; }
.icon-cable:before { content: "\e90b"; }
.icon-cart:before { content: "\e90c"; }
.icon-check:before { content: "\e90d"; }
.icon-check-circle:before { content: "\e90e"; }
.icon-check-circle-fill:before { content: "\e90f"; }
.icon-circle:before { content: "\e910"; }
.icon-circle-fill:before { content: "\e911"; }
.icon-document-badge:before { content: "\e912"; }
.icon-document-bullets:before { content: "\e913"; }
.icon-document-pages:before { content: "\e914"; }
.icon-door-exit:before { content: "\e915"; }
.icon-download:before { content: "\e916"; }
.icon-download-2:before { content: "\e917"; }
.icon-elipsis:before { content: "\e918"; }
.icon-envelope:before { content: "\e919"; }
.icon-eye:before { content: "\e91a"; }
.icon-facebook:before { content: "\e91b"; }
.icon-flat:before { content: "\e91c"; }
.icon-flat-bold:before { content: "\e91d"; }
.icon-google-plus:before { content: "\e91e"; }
.icon-guitar-case:before { content: "\e91f"; }
.icon-headstock:before { content: "\e920"; }
.icon-headstock-45c:before { content: "\e921"; }
.icon-headstock-fill:before { content: "\e922"; }
.icon-headstock-fill-45c:before { content: "\e923"; }
.icon-info-circle-fill:before { content: "\e924"; }
.icon-instagram:before { content: "\e925"; }
.icon-life-preserver:before { content: "\e926"; }
.icon-lightning-bolt:before { content: "\e927"; }
.icon-logo:before { content: "\e928"; }
.icon-logo-riffstation-horizontal:before { content: "\e929"; }
.icon-logo-tone-horizontal:before { content: "\e92a"; }
.icon-logo-play-horizontal:before { content: "\e967"; }
.icon-menu:before { content: "\e92b"; }
.icon-mesh-circle:before { content: "\e92c"; }
.icon-minus-circle:before { content: "\e92d"; }
.icon-minus-light:before { content: "\e92e"; }
.icon-neck:before { content: "\e92f"; }
.icon-neck-45c:before { content: "\e930"; }
.icon-neck-circle-45c:before { content: "\e931"; }
.icon-note-double-eighth:before { content: "\e932"; }
.icon-note-flat:before { content: "\e933"; }
.icon-note-sharp:before { content: "\e934"; }
.icon-pencil:before { content: "\e935"; }
.icon-phone:before { content: "\e936"; }
.icon-pick:before { content: "\e937"; }
.icon-pick-left:before { content: "\e938"; }
.icon-pick-right:before { content: "\e939"; }
.icon-pick-up:before { content: "\e93a"; }
.icon-picks:before { content: "\e93b"; }
.icon-pickup:before { content: "\e93c"; }
.icon-pinterest:before { content: "\e93d"; }
.icon-play:before { content: "\e93e"; }
.icon-play-circle:before { content: "\e93f"; }
.icon-plus-circle:before { content: "\e940"; }
.icon-plus-light:before { content: "\e941"; }
.icon-pointer-down:before { content: "\e942"; }
.icon-pointer-down-light:before { content: "\e943"; }
.icon-pointer-left:before { content: "\e944"; }
.icon-pointer-left-light:before { content: "\e945"; }
.icon-pointer-right:before { content: "\e946"; }
.icon-pointer-right-light:before { content: "\e947"; }
.icon-pointer-up:before { content: "\e948"; }
.icon-pointer-up-light:before { content: "\e949"; }
.icon-printer:before { content: "\e94a"; }
.icon-question-mark:before { content: "\e94b"; }
.icon-question-mark-circle-fill:before { content: "\e94c"; }
.icon-ruler:before { content: "\e94d"; }
.icon-ruler-45c:before { content: "\e94e"; }
.icon-search:before { content: "\e94f"; }
.icon-share:before { content: "\e950"; }
.icon-sharp:before { content: "\e951"; }
.icon-sharp-bold:before { content: "\e952"; }
.icon-sliders:before { content: "\e953"; }
.icon-snapchat:before { content: "\e954"; }
.icon-speaker:before { content: "\e955"; }
.icon-star:before { content: "\e956"; }
.icon-star-fill:before { content: "\e957"; }
.icon-star-fill-half:before { content: "\e958"; }
.icon-thumbs-up:before { content: "\e959"; }
.icon-triangle-down:before { content: "\e95a"; }
.icon-triangle-left:before { content: "\e95b"; }
.icon-triangle-right:before { content: "\e95c"; }
.icon-triangle-up:before { content: "\e95d"; }
.icon-tube:before { content: "\e95e"; }
.icon-tumblr:before { content: "\e95f"; }
.icon-twitter:before { content: "\e960"; }
.icon-user:before { content: "\e961"; }
.icon-user-circle:before { content: "\e962"; }
.icon-wifi:before { content: "\e963"; }
.icon-wood-grain-circle:before { content: "\e964"; }
.icon-x:before { content: "\e965"; }
.icon-youtube:before { content: "\e966"; }

